import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  name = new BehaviorSubject<string>('');
  private displayManageUser = new BehaviorSubject(false);
  displayManageUser$ = this.displayManageUser.asObservable();
  style: HTMLStyleElement;

  constructor() {
    this.style = this.getStopScrollStyle();
  }

  setUserName(userName: string) {
    this.name.next(userName);
  }

  changeData(displayManageUser: boolean) {
    this.displayManageUser.next(displayManageUser);
  }

  public stopBodyScroll() {
    document.body.appendChild(this.style);
  }
  public allowBodyScroll() {
    document.body.removeChild(this.style);
  }

  public getStopScrollStyle() {
    const style = document.createElement('style');
    style.textContent = `
			body {
				overflow-y: hidden ;
			}
		`;
    return style;
  }

  getFormatedDate(timestamp: string, showTime = false): string {
    const regexPattern = /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2}).*/;
    const matchedGroups = timestamp.match(regexPattern);
    const year = matchedGroups[1];
    const month = matchedGroups[2];
    const day = matchedGroups[3];
    if (showTime) {
      let hours = parseInt(matchedGroups[4], 10);
      const minutes = matchedGroups[5];
      const seconds = matchedGroups[6];
      let clock = 'AM';
      if (hours >= 12) {
        if (hours < 24) {
          hours %= 12;
          clock = 'PM';
        } else if (hours === 24) {
          hours = 12;
        }
      }
      return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds} ${clock} `;
    }
    const date = `${day}/${month}/${year}`;
    return date;
  }
}
