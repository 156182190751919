import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { VersionGuard } from './shared/helpers/version.guard';

const routes: Routes = [
  { 
    path: 'login', 
    component: LoginComponent 
  },
  {
    path: '',
    canMatch: [VersionGuard],
    loadChildren: () => import('./version2/version2.module').then(m => m.Version2Module)
  },
  {
    path: '',
    loadChildren: () => import('./version1/version1.module').then(m => m.Version1Module)
  },
  { 
    path: '**', 
    redirectTo: '' 
  }
];

export const appRoutingModule = RouterModule.forRoot(routes);
