import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from "@azure/msal-angular";
import { RedirectRequest } from '@azure/msal-browser';

import { User } from '../models/user';
import { environment } from '../../../environments/environment';
import { CommonService } from './common-service.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private userInfo: User;
  homeContent: any;
  // private loginUrl = `${environment.BASE_URL}${environment.BASE_API.LOGIN}`;
  private loginUrl = `${environment.LOGIN_URL}`;
  private loginSsoUrl = `${environment.LOGIN_SSO_URL}`;
  private staticDataUrl  = `${environment.BASE_URL}${environment.BASE_API.STATIC}`;

  constructor(
    private http: HttpClient,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, 
    private msalService: MsalService,
    private commonService: CommonService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    return this.http
      .post<any>(this.loginUrl, { username, password })
      .pipe(
        concatMap((user) => {
          this.userInfo = user;
          return this.http.get(this.staticDataUrl, {
            headers: { 'Authorization': this.userInfo.idtoken},
          });
        }),
        tap(staticDataResp => {
          this.userInfo.CHANNELS = staticDataResp['CHANNELS'];
          this.userInfo.HOME = staticDataResp['HOME'];
          this.userInfo.PROFILES = staticDataResp['PROFILES'];
          this.userInfo.CANDIDATE_LIST_SIZE=staticDataResp["SCREEN_RECORD_COUNT"]["VIEW_CANDIDATE_LIST_1"];
          localStorage.setItem('currentUser', JSON.stringify(this.userInfo));
          localStorage.setItem('currentUserName', username);
          this.currentUserSubject.next(this.userInfo);
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.clear();
    sessionStorage.clear();
    this.currentUserSubject.next(null);
  }

  loginSso() {
    if (this.msalGuardConfig.authRequest) {
        this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
        this.msalService.loginRedirect();
    }
}

  loginUsingSso(loginData) {
    return this.http
      .post<any>(this.loginSsoUrl, loginData)
      .pipe(
        concatMap((user) => {
          this.userInfo = user;
          return this.http.get(this.staticDataUrl, {
            headers: { 'Authorization': this.userInfo.idtoken},
          });
        }),
        tap(staticDataResp => {
          this.userInfo.CHANNELS = staticDataResp['CHANNELS'];
          this.userInfo.HOME = staticDataResp['HOME'];
          this.userInfo.PROFILES = staticDataResp['PROFILES'];
          this.userInfo.CANDIDATE_LIST_SIZE=staticDataResp["SCREEN_RECORD_COUNT"]["VIEW_CANDIDATE_LIST_1"];
          localStorage.setItem('currentUser', JSON.stringify(this.userInfo));
          localStorage.setItem('currentUserName', this.userInfo["user"]["USERNAME"]);
          this.commonService.setUserName(this.userInfo["user"]["USERNAME"]);
          this.currentUserSubject.next(this.userInfo);
        })
      );
  }
}
