import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {NgxPaginationModule} from 'ngx-pagination';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {InputSwitchModule} from 'primeng/inputswitch';
import {PasswordModule} from 'primeng/password';
import {ToggleButtonModule} from 'primeng/togglebutton';
import { MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

import { appRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { NotificationService } from './notification/notification.service';
import { NotificationComponent } from './notification/notification.component';
import { ErrorInterceptor } from './shared/helpers/error.interceptor';
import { JwtInterceptor } from './shared/helpers/jwt.interceptor';
import { SharedModule } from './shared/shared.module';
import { NavbarComponent } from './version2/navbar/navbar.component';
import { environment } from '../environments/environment';

const msalConfig = environment.MSAL_CONFIG;
const isIE = window.navigator.userAgent.indexOf('MSIE') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        appRoutingModule,
        NgxPaginationModule,
        InputTextModule,
        PasswordModule,
        ButtonModule,
        ToggleButtonModule,
        SharedModule,
        InputSwitchModule,
        MsalModule.forRoot(
            new PublicClientApplication({
              auth: {
                clientId: msalConfig.clientId,
                authority: msalConfig.authority,
                redirectUri: msalConfig.redirectUri,
                postLogoutRedirectUri: msalConfig.postLogoutRedirectUri
              },
              cache: {
                cacheLocation: 'sessionStorage',
                storeAuthStateInCookie: isIE
              }
            }),
            {
              interactionType: InteractionType.Redirect,
              authRequest: {
                scopes: ["user.read"]
              }
            },
            {
              interactionType: InteractionType.Redirect,
              protectedResourceMap: new Map(
                [
                  ["https://graph.microsoft.com/v1.0/me", ["user.read"]]
                ]
              )
            }
          )
      
    ],
    declarations: [
        AppComponent,
        LoginComponent,
        NotificationComponent,
        NavbarComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        NotificationService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
