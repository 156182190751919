<app-loader *ngIf="fetchData || (loginLoader === 'true'? true: false)"></app-loader>
<div> 
  <div class="outer-container">
    <div class="login-img">
      <img src="assets/wg-rb-login-img.png" alt="{{ authPrompts.auth_graphic_alt_text }}">
    </div>
 
    <div class="form-container">
      <div class="logo-img">
        <img src="assets/wg-rb-logo.png" alt="{{ authPrompts.logo_alt_text }}">
      </div>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <input type="text"
            placeholder="{{ authPrompts.username }}"
            formControlName="username"
            pInputText 
            [ngClass]="{ 'is-invalid': (submitted && f.username.errors) || (f.username.errors && f.username.touched) }"/>
          <div *ngIf="(submitted && f.username.errors) || (f.username.errors && f.username.touched)" 
            class="invalid-feedback">
            <div *ngIf="f.username.errors.required">
              {{ authPrompts.username_required }}
            </div>
          </div>
        </div>
        <div class="form-group">
          <input type="password" 
            placeholder="{{ authPrompts.password }}"
            formControlName="password"
            pInputText  
            [ngClass]="{ 'is-invalid':(submitted && f.password.errors) || (f.password.errors && f.password.touched) }">
          <div *ngIf="(submitted && f.password.errors) || (f.password.errors && f.password.touched)" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">
              {{ authPrompts.password_required }}
            </div>
          </div>
        </div>
        <div class="form-group reset-password">
          <a target="_blank" href="{{ resetPasswordUrl }}">{{ authPrompts.forgot_password }}</a>
        </div>
        <div class="form-group">
          <button pButton
            id="login-btn"
            type="submit"
            label="{{ authPrompts.login_btn }}"
            [disabled]="loading"
          >
          </button>
        </div>
      </form>
      <p>OR</p>
      <button pButton
            id="login-btn"
            type="submit"
            label="{{ authPrompts.login_sso_btn }}"
            class="p-button-outlined"
            (click)="onSsoLogin()"
          >
      </button>
    </div>
  </div>
</div>

<!-- <app-loader *ngIf="loginLoader === 'true'? true: false"></app-loader> -->
