export class User {
  id: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  idtoken: string;
  HOME: any;
  CHANNELS: any;
  PROFILES: any;
  CANDIDATE_LIST_SIZE: number;
  BETA_FEATURES_FLAGS: BetaUIFlag;
}

export class BetaUIFlag {
  BETA_UI_V2: boolean;
}

export interface UserDetailInterface {
  username: string;
  email: string;
  role: string;
  profile: string;
  vendor: string;
}

export interface EachIndividualUserInterface extends UserDetailInterface {
  actions: string[];
}

export enum Screens {
  ADD_CANDIDATE = 'ADD_CANDIDATE_1',
  CANDIDATE_LIST = 'VIEW_CANDIDATE_LIST_1',
  VIEW_PIPELINE = 'VIEW_PIPELINE_1',
  SEARCH_CANDIDATE = 'SEARCH_CANDIDATE_1',
}
