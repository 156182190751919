export const AUTH_PROMPTS = {
    auth_graphic_alt_text: "authentication graphic",
    logo_alt_text: "recruitment buddy logo",
    username: "Username",
    password: "Password",
    username_required: "Username is required",
    password_required: "Password is required",
    forgot_password: "Forgot Password?",
    login_btn: "Login",
    login_sso_btn: "Login using SSO"
}

export const NAVBAR_PROMPTS = {
    app_name: "Recruitment Buddy",
    logout: "Logout",
    dashboard: "Dashboard",
    candidate_list: "Candidate List",
    add_candidate: "Add Candidate",
    candidate_pipeline: "Candidate Pipeline",
    search_candidate: "Search Candidate",
    logo_alt_text: AUTH_PROMPTS.logo_alt_text,
    new_version: "New Version"
}

export const DASHBOARD_PROMPTS = {
    candidates_overview: "Candidates Overview",
    total_candidates: "Total Candidates",
    cv: "CV",
    written: "Written",
    interview_1: "Interview 1",
    interview_2: "Interview 2",
    interview_m: "Interview M",
    offer: "Offer",
    joined: "Joined",
    panels_overview: "Panels Overview",
    panel_schedule_7_days: "Panel Schedule for Next 7 Days"
}

export const CANDIDATES_DOCUMENTS_PROMPTS = {
    document_heading: "Documents",
    add_document: "Add Document",
    no_documents_found: "No Documents Found",
    add_document_heading: "Add Document",
    upload_drag_drop: "Upload or drag and drop PDF files",
    unsupported_pdf: "Unsupported file type (Only PDFs are allowed)"
}

export const COMMENT_PROMPTS = {
    audit_comment_header_timestamp: "Timestamp",
    audit_comment_header_user: "User",
    audit_comment_header_event: "Event"
}

export const COMMENT_LIST_PROMPTS = {
    no_comments_added: "No Comments Added"
}
