import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    let currentUser = this.authenticationService.currentUserValue;
    if (currentUser && currentUser.idtoken) {
      request = request.clone({
        setHeaders: {
          Authorization: `${currentUser.idtoken}`,
        },
      });
    }
    const localDev = window.location.href.indexOf('.com') === -1; //location.host
    const serverlessApi = this.isServerlessApi(request.url);

    if (localDev && serverlessApi) {
      request = this.getAroundCorsPolicy(request);

    }

    return next.handle(request);
  }

  private isServerlessApi(url: string): boolean {
    if (!url) {
      return false;
    }

    if (/api./.test(url)) {

      return true;
    }

    return false;
  }

  private getAroundCorsPolicy(req: HttpRequest<any>): HttpRequest<any> {
    if (/dev.api.rb/.test(req.url)) {
      return req.clone({
        url: req.url.replace('https://dev.api.rb.wginb.com', ''),
      });
    }
    if (/dev.api.sso/.test(req.url)) {
      return req.clone({
        url: req.url.replace('https://dev.api.sso.wginb.com', ''),
      });
    }
    return req;
  }
}
