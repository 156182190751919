import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { BetaFeaturesAPIResponse } from '../models/candidate';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class VersionSwitchService {

  constructor(
    private router: Router,
    private http: HttpClient
  ) {}

  private user: User;
  private betaFeatureUrl = `${environment.BASE_URL}${environment.BASE_API.BETA_FEATURE}`;

  changeBetaFeatureFlag(flag: boolean): Observable<BetaFeaturesAPIResponse>{
    return this.http.put<BetaFeaturesAPIResponse>(
      this.betaFeatureUrl, 
      {
        "BETA_UI_V2": flag
      }
    );
  }

  switchToNewVersion(): void {
    // to change beta-flag value in local storage
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.user.BETA_FEATURES_FLAGS.BETA_UI_V2 = true;
    localStorage.setItem('currentUser', JSON.stringify(this.user));
    
    // to reload the window again with updated UI
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      window.location.reload();
      this.router.navigate(['/']);
    });
  }

  switchToOldVersion(): void {
    // to change beta-flag value in local storage
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.user.BETA_FEATURES_FLAGS.BETA_UI_V2 = false;
    localStorage.setItem('currentUser', JSON.stringify(this.user));

    // to reload the window again with updated UI
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      window.location.reload();
      this.router.navigate(['/']);
    });
  }
}
