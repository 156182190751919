import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from '../models/user';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class VersionGuard {

    authenticationSubscription$: Subscription;
    currentUser: User;
    betaFeatureFlag: boolean;

    constructor(
        private authenticationService: AuthenticationService
    ) {}

    canMatch(): boolean {
        this.authenticationSubscription$ = this.authenticationService.currentUser
            .subscribe({
                next: (user) => {
                    this.currentUser = user;
                    
                    if (this.currentUser) {
                        this.betaFeatureFlag = this.currentUser.BETA_FEATURES_FLAGS.BETA_UI_V2;
                    }
                    else {
                        this.betaFeatureFlag = false;
                    }
                }
        });
        return this.betaFeatureFlag ? true : false;
    }
}
