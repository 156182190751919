import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { finalize, first } from 'rxjs/operators';

import { AuthenticationService } from './shared/services/authentication.service';
import { Screens, User } from './shared/models/user';
import { CommonService } from './shared/services/common-service.service';
import { VersionSwitchService } from './shared/services/version-switch.service';
import { NAVBAR_PROMPTS } from './shared/ui-strings';
import { NotificationService } from './notification/notification.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    navbarPrompts = NAVBAR_PROMPTS;
    currentUser: User;
    screens = Screens;
    userName = '';
    screenAccessSet = new Set();
    betaFeatureFlag: boolean;
    fetchData = false;
    authenticationSubscription$: Subscription;
    commonSubscription$: Subscription;
    versionSubscription$: Subscription;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private commonService: CommonService,
        private notificationSvc: NotificationService,
        private versionSwitchService: VersionSwitchService
    ) { }

    ngOnInit(): void {
        this.fetchCurrentUser();
    }

    private fetchCurrentUser(): void {
        this.authenticationSubscription$ = this.authenticationService.currentUser
            .subscribe({
                next: (user) => {
                    this.currentUser = user;
                    this.screenAccessSet = new Set(user ? user.HOME : []);
                    
                    if (localStorage.getItem('currentUser')) {
                        let userInfo: User = JSON.parse(localStorage.getItem('currentUser'))
                        this.betaFeatureFlag = userInfo.BETA_FEATURES_FLAGS.BETA_UI_V2;
                    }
                    else {
                        this.betaFeatureFlag = (user?.BETA_FEATURES_FLAGS.BETA_UI_V2);
                    }
                }
            });
        
        this.commonSubscription$ = this.commonService.name
            .subscribe({
                next: (data) => {
                    if (data.length > 0) {
                        this.userName = data;
                    } else {
                        this.userName = localStorage.getItem('currentUserName');
                    }
                }
            });
    }

    logout(): void {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }

    showMenuItem(screen: Screens): boolean {
        return this.screenAccessSet.has(screen);
    }

    toggleToNewVersion(): void {
        this.fetchData = true;
        this.versionSubscription$ = this.versionSwitchService
            .changeBetaFeatureFlag(true)
            .pipe(
                first(),
                finalize(() => {
                    this.fetchData = false;
                })
            )
            .subscribe({
                next: (_) => {
                    this.versionSwitchService.switchToNewVersion();
                },
                error: (error) => {
                    this.notificationSvc.error(error, 20000);
                }
            });
    }

    ngOnDestroy(): void {
        this.authenticationSubscription$?.unsubscribe();
        this.commonSubscription$?.unsubscribe();
        this.versionSubscription$?.unsubscribe();
    }
}
