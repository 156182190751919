import { NgModule } from "@angular/core";

import { AccordionModule } from 'primeng/accordion';
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';

import { LoaderComponent } from "../loader/loader.component";


@NgModule({
    declarations: [
        LoaderComponent
    ],
    imports: [
        AccordionModule,
        ProgressSpinnerModule,
        TableModule,
        InputTextModule,
        ButtonModule
    ],
    exports: [
        AccordionModule,
        LoaderComponent,
        TableModule,
        InputTextModule,
        ButtonModule
    ]
})
export class SharedModule { }
