import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { finalize, first } from 'rxjs/operators';

import { AuthenticationService } from '../../shared/services/authentication.service';
import { Screens, User } from '../../shared/models/user';
import { VersionSwitchService } from '../../shared/services/version-switch.service';
import { NAVBAR_PROMPTS } from '../../shared/ui-strings';
import { NotificationService } from '../../notification/notification.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnDestroy {

    @Input() betaFeatureFlag: boolean;
    @Input() currentUser: User;
    @Input() screenAccessSet = new Set();
    @Input() userName: string;
    screens = Screens;
    navbarPrompts = NAVBAR_PROMPTS;
    fetchData = false;
    versionSubscription$: Subscription;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private versionSwitchService: VersionSwitchService,
        private notificationSvc: NotificationService,
    ) { }

    logout(): void {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }

    showMenuItem(screen: Screens): boolean {
       return this.screenAccessSet.has(screen);
    }

    toggleToOldVersion(): void {
        this.fetchData = true;
        this.versionSubscription$ = this.versionSwitchService
        .changeBetaFeatureFlag(false)
        .pipe(
            first(),
            finalize(() => {
                this.fetchData = false;
            })
        )
        .subscribe({
            next: (_) => {
                this.versionSwitchService.switchToOldVersion();
            },
            error: (error) => {
                this.notificationSvc.error(error, 20000);
            }
        });
    }

    ngOnDestroy(): void {
        this.versionSubscription$?.unsubscribe();
    }
}
