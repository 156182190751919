// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  BASE_URL: 'https://dev.api.rb.wginb.com/',
  LOGIN_URL: 'https://dev.api.rb.wginb.com/rblogin/login',
  LOGIN_SSO_URL: 'https://dev.api.rb.wginb.com/rblogin/ssologin',
  RESET_PASSWORD: "https://dev.portal.reset.wginb.com",
  BASE_API: {
    STATIC: 'rb/static',
    CANDIDATE: 'rb/candidate',
    PIPELINE: 'rb/pipeline',
    DASHBOARD: 'rb/pipeline/dashboard',
    SEARCH: 'rb/candidate/search',
    BETA_FEATURE: 'rb/beta/features'
  },
  MSAL_CONFIG: {
    clientId:"f529d809-fdd8-4726-9659-2fc6c6f8f51d",
    authority: "https://login.microsoftonline.com/2563c132-88f5-466f-bbb2-e83153b3c808",
    redirectUri: "https://dev.buddy4recruitment.wginb.com",
    postLogoutRedirectUri: 'https://dev.buddy4recruitment.wginb.com'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
