<header 
  class="rb-header" 
  *ngIf="currentUser && !betaFeatureFlag"
>
  <!-- nav -->
  <nav class="navbar navbar-expand rb-navbar">
    <div class="navbar-nav rb-navbar-nav">
      <a routerLink="/" style="color: #fff" class="navbar-brand mb-0 h1 wg-brand" >
        <img src="assets/wg-logo-white.svg"
          width="110px" 
          height="33px" 
          alt="{{ navbarPrompts.logo_alt_text }}"
          class="d-inline-block align-top"/>
      </a>
      <div class="rb-heading">
        {{ navbarPrompts.app_name }}
      </div>
      <div class="right rb-nav-menu" style="display: flex">
        <a class="nav-item nav-link no-event">
          <i style="margin-right: 5px" class="bi bi-person-circle"></i>
          {{ userName }}
        </a>
        <a class="nav-item nav-link" (click)="logout()">
          {{ navbarPrompts.logout }}
        </a>
      </div>
    </div>
  </nav>

  <nav class="navbar navbar-expand rb-navbar pt-0">
    <div class="navbar-nav rb-navbar-nav">
      <div class="rb-nav-menu">
        <a routerLink="/" class="nav-item nav-link" >
          <i class="bi-house-fill"></i>
        </a>
        <a routerLink="/candidate-list" 
          routerLinkActive="active"
          class="nav-item nav-link"
          *ngIf="showMenuItem(screens.CANDIDATE_LIST)" 
        >
          {{ navbarPrompts.candidate_list }}
        </a>
        <a routerLink="/add-candidate" 
          routerLinkActive="active"
          class="nav-item nav-link" 
          *ngIf="showMenuItem(screens.ADD_CANDIDATE)" 
        >
          {{ navbarPrompts.add_candidate }}
        </a>
        <a routerLink="/view-pipeline" 
          routerLinkActive="active"
          class="nav-item nav-link"
          *ngIf="showMenuItem(screens.VIEW_PIPELINE)" 
        >
          {{ navbarPrompts.candidate_pipeline }}
        </a>
        <a routerLink="/search-candidate" 
          routerLinkActive="active"
          class="nav-item nav-link" 
          *ngIf="showMenuItem(screens.SEARCH_CANDIDATE)" 
        >
          {{ navbarPrompts.search_candidate }}
        </a>
      </div>  
    </div>
    <div class="toggle-btn">
      <p>{{ navbarPrompts.new_version }}</p>
      <p-inputSwitch [(ngModel)]="betaFeatureFlag" (onChange)="toggleToNewVersion()"></p-inputSwitch>
    </div>
  </nav>
</header>

<app-navbar *ngIf="currentUser && betaFeatureFlag" 
  [betaFeatureFlag]="betaFeatureFlag"
  [currentUser]="currentUser"
  [screenAccessSet]="screenAccessSet"
  [userName]="userName"
></app-navbar>


<!-- main app container -->
<div class="background">
  <div class="bottom">
    <app-notification></app-notification>
  </div>
  <div class="container-fluid"  *ngIf="!betaFeatureFlag; else newVersion">
    <div class="row">
      <div class="col-sm-12">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <ng-template #newVersion>
    <div class="main-content">
      <router-outlet></router-outlet>
    </div>
  </ng-template>
</div>

<app-loader *ngIf="fetchData"></app-loader>
