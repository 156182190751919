<header 
  class="rb-header" 
  *ngIf="currentUser"
>
  <!-- nav -->
  <nav class="navbar navbar-expand rb-navbar">
    <div class="navbar-nav rb-navbar-nav">
      <a routerLink="/" style="color: #fff" class="navbar-brand custom-logo">
          <img src="assets/logo_for_black_bg.png" 
            class="d-inline-block align-top" 
            alt="{{ navbarPrompts.logo_alt_text }}"/>
      </a>
      <h4>{{ navbarPrompts.app_name }}</h4>
      <div class="right rb-nav-menu" style="display: flex">
          <a class="nav-item nav-link no-event">
            <i style="margin-right: 5px" class="bi bi-person-circle"></i>
            {{ userName }}
          </a>
          <a class="nav-item nav-link" (click)="logout()">
            <i class="pi pi-sign-out"></i>
          </a>
      </div>
    </div>
  </nav>
   
  <nav class="navbar navbar-expand rb-subnavbar pt-0">
    <div class="navbar-nav rb-navbar-nav">
      <div class="rb-nav-menu">
        <a routerLink="/" 
          routerLinkActive="nav-link-active" 
          [routerLinkActiveOptions]="{exact: true}"
          class="nav-item nav-link" 
        >
          {{ navbarPrompts.dashboard }}
        </a>
        <a routerLink="/view-pipeline" 
          routerLinkActive="nav-link-active"
          class="nav-item nav-link" 
          *ngIf="showMenuItem(screens.VIEW_PIPELINE)" 
        >
          {{ navbarPrompts.candidate_pipeline }}
        </a>
        <a routerLink="/candidate-list" 
          routerLinkActive="nav-link-active"
          class="nav-item nav-link" 
          *ngIf="showMenuItem(screens.CANDIDATE_LIST)" 
        >
          {{ navbarPrompts.candidate_list }}
        </a>
        <a routerLink="/add-candidate" 
          routerLinkActive="nav-link-active"
          class="nav-item nav-link" 
          *ngIf="showMenuItem(screens.ADD_CANDIDATE)"
        >
          {{ navbarPrompts.add_candidate }}
        </a>
        <a routerLink="/search-candidate" 
          routerLinkActive="nav-link-active"
          class="nav-item nav-link" 
          *ngIf="showMenuItem(screens.SEARCH_CANDIDATE)" 
        >
          {{ navbarPrompts.search_candidate }}
        </a>
      </div>
    </div>
  </nav>
</header>

<app-loader *ngIf="fetchData"></app-loader>
