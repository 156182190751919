<div class="notifications">
  <div
    *ngFor="let notification of notifications"
    class="notification"
    [ngClass]="className(notification)">
    <ng-container
      *ngTemplateOutlet="notificationTpl;context:{notification:notification}">
    </ng-container>
  </div>
</div>

<ng-template #notificationTpl let-notification="notification">
  <div class="message">{{notification.message}}<a class="close" style="cursor: pointer;" (click)="close(notification)">&times;</a></div>
</ng-template>
